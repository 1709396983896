import React from 'react'
import Layout from '../components/Layout'
import Hero from '../components/Hero'

const NotFoundPage = () => (
  <Layout>
    <Hero title="" subtitle="" />
    <section className="section">
      <h1 className="title">
        <i class="fas fa-sad-tear"></i>
        &nbsp; not found
      </h1>
      <p>You just hit a route that doesn&#39;t exist... the sadness.</p>
    </section>
  </Layout>
)

export default NotFoundPage
